import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { Actions } from '@/store/enums/StoreEnums';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Shop } from '@/store/modules/data_interfaces/ShopUserData';
import LocalStorageService from '@/core/services/LocalStorageService';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');
import { order_status } from '@/utils/mixins/config';
export default function HelperMixin() {
    const router = useRouter();
    const store = useStore();
    const selectShop = async (id: string, shop: Shop, allowClick = false) => {
        if (id === LocalStorageService.getShopID() && !allowClick) {
            return;
        }
        LocalStorageService.saveShopId(id);
        // LocalStorageService.saveShopPreferences(shop)
        await store.dispatch(Actions.UPDATE_SHOP_PREFERENCE_LOCALY, shop);
        if (router.currentRoute.value.name === 'dashboard') {
            router.go(0);
        } else {
            await router.push({ name: 'dashboard' });
        }
        window.location.reload();
    };

    // upload file
    const uploadFile = async (file: any) => {
        // upload a file
        const formData = new FormData();
        formData.append('file', file);
        await store.dispatch(Actions.UPLOAD_FILE, formData);
        const [errorName] = Object.keys(store.getters.getUploadFileErrors);
        const error = store.getters.getUploadFileErrors[errorName];
        if (!error) {
            return (
                process.env.VUE_APP_API_URL +
                '/attachments/' +
                store.getters.getFile.file
            );
        } else {
            showAlert(error, 'error');
        }
    };

    // show toast
    const showToast = (message: string, intent?: string) => {
        const toast = useToast();
        const timeout = 2000;

        switch (intent) {
            case 'info':
                return toast.info(message, { timeout: timeout });

            case 'success':
                return toast.success(message, { timeout: timeout });

            case 'error':
                return toast.error(message, { timeout: timeout });

            case 'warning':
                return toast.warning(message, { timeout: timeout });

            default:
                return toast(message, { timeout: timeout });
        }
    };

    // show alert
    const showAlert = (
        text: string,
        icon: string,
        title?: string,
        confirmButtonText?: string,
        showCancelButton?: boolean,
        allowOutsideClick?: boolean
    ) => {
        return Swal.fire({
            text,
            icon,
            title: title ? title : '',
            buttonsStyling: false,
            confirmButtonText: confirmButtonText
                ? confirmButtonText
                : 'Ok, got it!',
            showCancelButton: showCancelButton ? showCancelButton : false,
            allowOutsideClick: allowOutsideClick === false ? false : true,
            customClass: {
                text: 'fw-semobold',
                confirmButton:
                    icon === 'error' || icon === 'warning'
                        ? 'btn btn-light-danger'
                        : 'btn btn-primary',
                cancelButton: 'btn fw-semobold btn-secondary',
            },
        });
    };

    // Generate time
    function fill_time(options, diff = 5) {
        let hour = 12;
        let count = 0;
        let meridiem = 'am';
        let time;

        while (count < 2) {
            for (let min = 0; min < 60; min += diff) {
                time = hour + ':' + ('0' + min).slice(-2) + meridiem;
                options.push({
                    value: time,
                    label: time,
                    disabled: false,
                });
            }
            if (hour == 11) {
                count++;
            }
            hour++;
            if (hour == 13) {
                hour = 1;
            }
            if (hour == 12) {
                meridiem = 'pm';
            }
        }
    }

    async function isShopSelected() {
        const shop_id = await LocalStorageService.getShopID();

        if (shop_id === '' || shop_id === null) {
            await router.push({ name: 'select-shop' });
            return false;
        }
        return true;
    }

    function formateWordsWithUnderScore(inputStr) {
        // Split the input string into individual words using the underscore as the delimiter
        const words = inputStr.split('_');

        // Capitalize the first letter of each word
        const formattedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        //check if input string is order_cancelled_refunded send cancelled
        if (inputStr === order_status.order_cancelled) {
            const outputStr = 'Cancelled';
            return outputStr;
        }
        // Join the words back together using a space as a separator
        const outputStr = formattedWords.join(' ');

        return outputStr;
    }
    function getItems(order_details, get_all) {
        let items: any = '';
        for (let i = 0; i < order_details?.length; i++) {
            if (i !== 0) {
                items += ', ';
            }
            items +=
                order_details[i].menu_name +
                '(' +
                order_details[i].quantities +
                ')';
        }
        if (get_all) {
            return items;
        } else {
            if (items.length > 50) {
                return items.slice(0, 50) + ' ...';
            } else {
                return items;
            }
        }
    }
    function unix_to_date(unix) {
        return moment.unix(unix).format('MMM DD, YYYY');
    }
    function checkTime(requestTimeUnix: number, minutes: number) {
        try {
            moment.tz.setDefault('Europe/London');
            const requestTimeObj = moment.unix(requestTimeUnix);
            requestTimeObj.add(minutes, 'minutes');

            const now = moment();
            return now.isAfter(requestTimeObj);
        } catch (error) {
            // Handle the error if needed
            console.error(error);
            return false;
        }

        return false;
    }
    function checkEnv() {
        if (
            process.env.VUE_APP_RUNNING_ENV !== undefined &&
            (process.env.VUE_APP_RUNNING_ENV === 'local' ||
                process.env.VUE_APP_RUNNING_ENV === 'pr')
        ) {
            return true;
        }
        return false;
    }
    return {
        uploadFile,
        selectShop,
        showToast,
        showAlert,
        fill_time,
        isShopSelected,
        formateWordsWithUnderScore,
        getItems,
        checkTime,
        unix_to_date,
        checkEnv,
    };
}
