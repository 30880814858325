
import { defineComponent, nextTick, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { Mutations, Actions } from '@/store/enums/StoreEnums';
import { themeMode } from '@/core/helpers/config';
import { initializeComponents } from '@/core/plugins/keenthemes';
import { useRoute, useRouter } from 'vue-router';
import useHelperMixin from '@/utils/mixins/useHelperMixin';
import LocalStorageService from '@/core/services/LocalStorageService';
import { User } from '@/store/modules/AuthModule';

export default defineComponent({
    name: 'app',
    setup() {
        const { showAlert } = useHelperMixin();

        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const isLoading = ref(false);

        let query = route.query;
        if (!query.token) {
            // Manually extract the token from the URL
            const urlParams = new URLSearchParams(
                window.location.hash.split('?')[1]
            );
            query = Object.fromEntries(urlParams.entries());
        }

        // Function to call the API
        const verifyToken = async () => {
            store.dispatch(Actions.LOGOUT);
            isLoading.value = true;
            try {
                await store.dispatch(Actions.ONE_CLICK_LOGIN, {
                    token: query.token,
                });

                const [errorName] = Object.keys(store.getters.getErrors);
                const error = store.getters.getErrors[errorName];
                if (!error) {
                    const userShopId = LocalStorageService.getShopID();
                    let user = store.getters.currentUser;

                    let userObject: User = {
                        _id: user._id,
                        name: user.name,
                        email: user.email,
                        image: user.image,
                    };
                    LocalStorageService.saveUserObject(userObject);
                    LocalStorageService.saveAllShopIds(user.shop_ids);
                    await store.dispatch(Actions.GET_USER_SHOPS);
                    const [errorName] = Object.keys(
                        store.getters.getShopErrors
                    );
                    const error = store.getters.getShopErrors[errorName];
                    if (!error) {
                        const all_shop_users = store.getters.getUsersShops;
                        const ids: any = [];
                        if (all_shop_users.length > 0) {
                            let shopData = all_shop_users.find(
                                (shop) => shop._id === userShopId
                            );
                            await store.dispatch(
                                Actions.UPDATE_SHOP_PREFERENCE_LOCALY,
                                shopData
                            );
                            router.push({ name: 'dashboard' });
                        }
                        for (let i = 0; i < all_shop_users.length; i++) {
                            ids.push(all_shop_users[i]._id);
                        }
                        LocalStorageService.saveAllShopIds(ids);
                    }
                } else {
                    showAlert(error, 'error', undefined, 'Try again!');
                }
            } catch (error) {
                console.error('API Error:', error);
            } finally {
                isLoading.value = false; // Hide loader
            }
        };
        if (query.token) verifyToken();
        onMounted(() => {
            /**
             * Overrides the layout config using saved data from localStorage
             * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

            /**
             *  Sets a mode from configuration
             */
            store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

            nextTick(() => {
                initializeComponents();

                store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
            });
        });
        return {
            isLoading,
        };
    },
});
