import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import LocalStorageService from '@/core/services/LocalStorageService';

export interface User {
    _id: string;
    name: string;
    email: string;
    image: string;
    password?: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
    message: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();
    message = '';
    tosave = {};
    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    get getMessage(): string {
        return this.message;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_MESSAGE](message) {
        this.message = message;
    }

    @Mutation
    [Mutations.SET_AUTH](payload) {
        this.isAuthenticated = true;
        this.errors = {};
        this.user = payload.user;
        JwtService.saveToken(payload.api_token);
    }

    @Mutation
    [Mutations.SET_USER](user: User) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password: string) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
        LocalStorageService.destroyShopID();
        LocalStorageService.destroyShopPreferences();
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post('/shop/auth/login', credentials)
            .then(({ data }) => {
                const payload = {
                    user: data.user,
                    api_token: data.token,
                };
                this.context.commit(Mutations.SET_AUTH, payload);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error,
                    ]);
                }
            });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post('/shop/auth/reset_password', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_MESSAGE, data.message);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.message,
                ]);
            });
    }

    @Action
    [Actions.RESET_PASSWORD](payload) {
        return ApiService.get(
            `/shop/auth/check_password_verification_code?email=${payload.email}&code=${payload.code}&password=${payload.password}`
        )
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.message,
                ]);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post('/shop/auth/refresh', payload)
                .then(({ data }) => {
                    const payload = {
                        api_token: JwtService.getToken(),
                        user: data.user,
                    };
                    this.context.commit(Mutations.SET_AUTH, payload);
                })
                .catch(({ response }) => {
                    this.context.commit(
                        Mutations.SET_ERROR,
                        response.data.errors
                    );
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }
    @Action
    [Actions.ONE_CLICK_LOGIN](_payload) {
        return ApiService.post('/shop/auth/one_click_login', _payload)
            .then(({ data }) => {
                const payload = {
                    user: data.user,
                    api_token: data.token,
                };
                LocalStorageService.saveShopId(data.shopId);
                this.context.commit(Mutations.SET_AUTH, payload);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error,
                    ]);
                }
            });
    }
}
